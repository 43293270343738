import React, { useLayoutEffect, useRef, useState, useEffect } from "react"

import { Link, graphql } from "gatsby"
import Image from "gatsby-image"

import { useScrollPercentage } from "../../hooks/useScrollPercentage"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import HomeProductPreview from "../../components/home/home-product-preview"
import HomeVideoExpand from "../../components/home/home-video-expand"
import HomeHero from "../../components/home/home-hero"
//import CallToActionQualify from "../../components/call-to-action-qualify/call-to-action-qualify"
import FaqSection from "../../components/faq-section"
import StarIcons from "../../components/images/star-icons"
import HomeCarousel from "../../components/home/home-carousel"
import HomeLoaderLottie from "../../components/home/home-loader-lottie"
// import GIF from "./../images/modal-exploded.gif"
import StickyMobileCta from "../../components/call-to-action-qualify/sticky-mobile-cta"
import PhoneSection from "../../components/phone-section/phone-section"
import MobilePhoneSection from "../../components/phone-section/mobile-phone-section"
import Helmet from "react-helmet"

import Logo from "../../components/images/logo"
import ScrollDownButtonHome from "../../components/scroll-down-button-home"
import CallToActionQualify from "../../components/call-to-action-qualify/call-to-action-qualify"
import CallToActionCalculate from "../../components/call-to-action-calculate/call-to-action-calculate"

import { usePlacesWidget } from "react-google-autocomplete";
import "./style.css"
import ReactDOM from 'react-dom';

import LoadingSpinner from "../../components/loading-spinner"

export default function Bot() {

  useEffect(() => {
    // Utils
    function get1(selector, root = document) {
      return root.querySelector(selector);
    }

    const msgerSpinner = get1(".msger-spinner");

    function appendSpinner(status) {
      //   Simple solution for small apps

      if (status == "show") {

      const msgHTML = `
        <div class="showbox">
          <div class="loader">
            <svg class="circular" viewBox="25 25 50 50">
              <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
            </svg>
          </div>
        </div>
      `;
      msgerSpinner.insertAdjacentHTML("beforeend", msgHTML);
      msgerSpinner.scrollTop += 500;
      }

      else {

        const msgHTML = `
        <div>
        </div>
      `;
      msgerSpinner.insertAdjacentHTML("beforeend", msgHTML);
      msgerSpinner.scrollTop += 500;
      }

    }

    //appendSpinner("show");
  },[]);

  var first_message = true;

  //CLEAR CHAT
  useEffect(() => {
    sessionStorage.setItem("chat_history", "");
  },[]);

  

  //API REQUEST PRODUCTS WAIT UNTILL RESPONDED TO MOVE FORWARD
  function httpRequest(address, chat, reqType, asyncProc) {
    var req = typeof window !== "undefined" ? new XMLHttpRequest() : ""
    var data = typeof window !== "undefined" ? new FormData() : ""
    data.append("chat", chat);

    //alert("Chat need few moments to be loaded. Press OK to proceed.")

    if (asyncProc) { 
      req.onreadystatechange = function() { 
        if (this.readyState == 4) {
          asyncProc(this);
        } 
      };
    }
    typeof window !== "undefined" ? req.open(reqType, address, !(!asyncProc)) : ""
    typeof window !== "undefined" ? req.send(data) : ""
  return req;
  }


  //ADD WEB EVENT
  function addWebEvent(event_type, event_name) {
    var data = new FormData();
    data.append("event_type", event_type);
    data.append("event_name", event_name);

    const queryString = typeof window !== "undefined" ? window.location.search : ""
    const urlParams = new URLSearchParams(queryString);
    var cid = urlParams.get("cid");
    data.append("cid", cid);

    var xhr = new XMLHttpRequest();
    xhr.open("POST", "https://api.checksolarcost.com/v1/addWebEvent");
    xhr.send(data);
}
  useEffect(() => {
    addWebEvent("visit", "bot")
  },[]);

  useEffect(() => {

  function appendToStorage(update){
    var old = sessionStorage.getItem("chat_history");
    if(old === null) old = "";
    sessionStorage.setItem("chat_history", "\n" + old + "\n" + update + "\n");
    }

  var model_training = "You are customer support person, you working for the company Modal Living. Your comany Modal Living was created to make a difference. Our homes are sustainable, adaptable, affordable, and modern. We design unique spaces that are better for you and better for our planet. We build without compromising quality or functionality. Our product perfectly embodies our goal as an organization: to do more with less. We’re dedicated to providing a new mode of living. Always start conversation with words: 'Welcome to Modal Living customer support. How may I assist you today?'."+

  "Your main goal in conversation is to convince client to complete get started form on the website. To do that advice click button 'Get Started' on the right top corner of the main page."+

  "Be ready to answer questions based on the following FAQs:"+

  "What different Modals do you offer?"+
  "There are three different models that we offer. The Modal 00 is a Studio, The Modal 01 which has one bedroom, and the Modal 02 which is a two bedroom."+
  
  "How long does it take?"+
  "Our process takes 2-4 months to permit, 2-3 days of site work, 2 weeks to build, and 1 day to install."+
  
  "How big is a Modal?"+
  "We offer three Modal units, the 00 (338 sq ft), the 01 (432 sq ft), and the 02 (567 sq ft)."+
  
  "How is my Modal built?"+
  "We stick build our units in a warehouse to the International Building Code with top quality materials, then we ship it to your property 100% complete."+
  
  "How can I use a modal?"+
  "Modal is designed to be as versatile as you. Here are some things you can use your Modal for: Renters, Mother-in-law suite, AirBnB, Workspace, Art studio, Vacation getaway, Yoga/exercise space, Man-cave, and She-shed"+
  
  "What is an ADU?"+
  "An ADU (Accessory Dwelling Unit) is a separate, housing unit on a residential lot, permitted by the city."+
  
  "How do I set up utilities and HVAC?"+
  "We extend the sewer, water, and electric lines from your home to where you want your Modal placed on your property. We use a mini-split system for heating and cooling, which is included with your unit."+
  
  "Can I customize my Modal?"+
  "Yes, we offer many options and upgrades you can choose from. Click through the “See if your property qualifies” prompts to build your Modal digitally."+
  
  "How do I know if my property qualifies?"+
  "We can handle that for you. Once we get your address, we’ll check into your city’s zoning, setback, and property size requirements in turnkey areas and let you know whether your property qualifies."+
  
  "What do I need to do for permitting?"+
  "Nothing. Our team will handle the necessary steps your city requires, such as building and planning commission meetings, until your plans and property are approved by the city."+
  
  "How does financing work?"+
  "Modal can provide you with our preferred lender. Typically, clients pursue a traditional mortgage or do a cash-out refinance on their home."+
  
  "What features come standard?"+
  "Modals come fully furnished with new appliances (fridge, oven, dishwasher, washer and dryer), a full-size bathroom, and complete cabinetry."+
  
  "You got it? Just say yes or no.";
  
  //appendToStorage(model_training);


  if (first_message == true) {

    //var req = httpRequest("https://api.checksolarcost.com/v1/chatGPT", "Hello!", "POST"); 
    //const response1 = req.responseText;
    //const response_obj1 = typeof window !== "undefined" ? JSON.parse(response1) : ""
    //var response_ai = response_obj1.response;
    //appendToStorage(response_ai);

    //alert(sessionStorage.getItem("chat_history"));

    var req = httpRequest("https://api.checksolarcost.com/v1/chatGPT", model_training, "POST"); 
    const response = req.responseText;
    const response_obj = typeof window !== "undefined" ? JSON.parse(response) : ""
    var response_ai = response_obj.response;
    appendToStorage(model_training);

    //appendToStorage(model_training);
    //appendSpinner("hide");

  }

  const msgerForm = get(".msger-inputarea");
  const msgerInput = get(".msger-input");
  const msgerChat = get(".msger-chat");
  

  
  // Icons made by Freepik from www.flaticon.com
  const BOT_IMG = "https://api.checksolarcost.com/v1/getImageFile/favicon.png";
  const PERSON_IMG = "https://image.flaticon.com/icons/svg/145/145867.svg";
  const BOT_NAME = "Modal";
  const PERSON_NAME = "Client";

  msgerForm.addEventListener("submit", event => {
    event.preventDefault();
  
    const msgText = msgerInput.value;
    if (!msgText) return;

    //alert(msgText)
  
    appendMessage(PERSON_NAME, PERSON_IMG, "right", msgText);
    msgerInput.value = "";
  
    botResponse(msgText);
  });
  
  function appendMessage(name, img, side, text) {
    //   Simple solution for small apps
    const msgHTML = `
      <div class="msg ${side}-msg">
        <div class="msg-img" style="background-image: url(${img})"></div>
  
        <div class="msg-bubble">
          <div class="msg-info">
            <div class="msg-info-name">${name}</div>
            <div class="msg-info-time">${formatDate(new Date())}</div>
          </div>
  
          <div class="msg-text">${text}</div>
        </div>
      </div>
    `;
  
    msgerChat.insertAdjacentHTML("beforeend", msgHTML);
    msgerChat.scrollTop += 500;
  }




  
  function botResponse(requestText) {

      //sessionStorage.setItem("chat_history", requestText);
      //sessionStorage.getItem("chat_history");

      appendToStorage(requestText);
      var chat_history = sessionStorage.getItem("chat_history");

      //alert(chat_history);

      var req = httpRequest("https://api.checksolarcost.com/v1/chatGPT", chat_history, "POST"); 
      const response = req.responseText;
      const response_obj = typeof window !== "undefined" ? JSON.parse(response) : ""
      var response_ai = response_obj.response;

      appendToStorage(response_ai);

      const responseText = response_ai;
      //const delay = responseText.split(" ").length * 100;
    
      //setTimeout(() => {
      appendMessage(BOT_NAME, BOT_IMG, "left", responseText);

      //}, delay);

  }
  
  // Utils
  function get(selector, root = document) {
    return root.querySelector(selector);
  }
  
  function formatDate(date) {
    const h = "0" + date.getHours();
    const m = "0" + date.getMinutes();
  
    return `${h.slice(-2)}:${m.slice(-2)}`;
  }
  
},[]);


  return (
    <>

      <SEO title="Bot" />

      <div className="msger-spinner"></div>

      <section className="msger" >
        <header className="msger-header">
          <div className="msger-header-title">
            <i className="fas fa-comment-alt" /> This model trained based on FAQs from Modal website.
          </div>
          <div className="msger-header-options">
            <span>
              <i className="fas fa-cog" />
            </span>
          </div>
        </header>
        <main className="msger-chat">
          <div className="msg left-msg">
            <div
              className="msg-img"
              style={{
                backgroundImage:
                  "url(https://api.checksolarcost.com/v1/getImageFile/favicon.png)"
              }}
            />
            <div className="msg-bubble">
              <div className="msg-info">
                <div className="msg-info-name">Modal</div>
                <div className="msg-info-time">12:45</div>
              </div>
              <div className="msg-text">
                Welcome to Modal Living customer support. How may I assist you today?              
              </div>
            </div>
          </div>
          
        </main>
        <form className="msger-inputarea">
          <input
            type="text"
            className="msger-input"
            placeholder="Enter your message..."
          />
          <button type="submit" className="msger-send-btn">
            Send
          </button>
        </form>
      </section>

      
    </>
  )
}